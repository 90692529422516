import React, { Component } from "react";
// import AccountDataService from "../services/account-activities";
// import { Redirect } from "react-router-dom";

import { connect } from 'react-redux';
// import { ACCESSTOKEN } from '../features/actions/Is-Logged-Actions';
// import { UPDATE_ACTIVE_LINK } from '../features/actions/Active-Nav-Link-Actions';

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { DragDropContext } from '@hello-pangea/dnd';
import HardwareAssetDataService from '../../services/hardware-asset-activities';
import { SET_DASHBOARD_TENANT_VIEWING } from '../../features/actions/Is-Logged-Actions';

import { RingProgress, Text, SimpleGrid, Paper, Center, Group, rem } from '@mantine/core';
import {
    Progress,
    Box,
    Table,
    TextInput,
    Flex,
    Button,
    Space,
    Select,
    Grid,
    keys,
    UnstyledButton,
    Container,
} from '@mantine/core';
import {
    DndContext,
    closestCenter,
    MouseSensor,
    TouchSensor,
    DragOverlay,
    useSensor,
    useSensors,
    DragStartEvent,
    DragEndEvent,
} from '@dnd-kit/core';
import { useDraggable } from '@dnd-kit/core';
import { CSS } from '@dnd-kit/utilities';

import { useDroppable } from '@dnd-kit/core';

import { arrayMove, SortableContext, sortableKeyboardCoordinates, rectSortingStrategy } from '@dnd-kit/sortable';

import Card from 'react-bootstrap/Card';

import { Link } from 'react-router-dom';

import Navbar from '../../components/Navbar';

import ListGroup from 'react-bootstrap/ListGroup';
//import dashboardActivities from "../../services/dashboard-activities";
import { MdFollowTheSigns } from "react-icons/md";
import { Doughnut, Line } from "react-chartjs-2";

//import ChartWidget from '../Chart-Widget';       // WILL END UP BEING SOMETHING DIFFERENT FOR ADMIN
import { useSortable } from '@dnd-kit/sortable';

export function SortableItem(props) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
    } = useSortable({ id: props.id });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition,
    };

    return (
        <div ref={setNodeRef} style={style} {...attributes} {...listeners}>
            {props.children}
        </div>
    );
}

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
        title: {
            display: true,
            text: 'Chart.js Line Chart',
        },
    },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];


class AdminDashboard extends Component {

    // componentDidMount() {
    //     this.props.UPDATE_ACTIVE_LINK("add_account");
    // }

    // onChangeName(e) {
    //     this.setState({
    //         name: e.target.value
    //     });
    // }

    constructor(props) {
        super(props);


        this.state = {
            items: [1, 2, 3, 4, 5],
            activeId: null,
            totalTickets: 0,
            unresolvedTickets: 0,
            tenants: [],     // [{id: 1, name_of_tenant: "LPA"}]
            line: {
                labels,
                datasets: [
                    {
                        label: 'Dataset 1',
                        data: [],
                        borderColor: 'rgb(255, 99, 132)',
                        backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                        label: 'Dataset 2',
                        data: [],
                        borderColor: 'rgb(53, 162, 235)',
                        backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                ],
            },
            data: {
                labels: ['Piority 1', 'Priority 2', 'Priority 3', 'Priority 4', 'Priority 5'],
                datasets: [
                    {
                        label: '# of Votes',
                        data: [12, 19, 3, 5, 2],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                        ],
                        borderWidth: 1,
                    },
                ],
            }
        };
    }

    // saveAccount() {
    //     var data = {
    //         name: this.state.name
    //     };

    //     if(this.props.loggedStatus.loggedIn){
    //         this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
    //         this.props.ACCESSTOKEN(token);
    //         AccountDataService.create(data, token)
    //         .then(response => {
    //             this.props.history.push('/accounts');
    //         //console.log(response.data);
    //         // this.setState({
    //         //     redirect: true
    //         // });
    //         })
    //         .catch(e => {
    //             console.log(e);
    //         });
    //         });
    //     }

    componentDidMount() {
        /* dashboardActivities.getUnresolvedTicketCount(this.props.loggedStatus.accessToken, this.props.loggedStatus.id).then(response => {
            this.setState({ unresolvedTickets: response.data.results.unresolvedTickets, totalTickets: response.data.results.totalTickets })
        }); */

        if (this.props.loggedStatus.loggedIn) {
            this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {

                HardwareAssetDataService.getTenants(token, this.props.loggedStatus.id).then(response => {
                    console.log(response);
                    this.setState({
                        tenants: response.data.results
                    }, () => {
                        //this.setReportTenant(this.state.tenants);   // update the global state with the tenant selected

                        // if this is first time landing on this page, we need to update the global state and local one
                        if (this.props.loggedStatus.dashboard_tenant_name_selected === "") {

                            if (response.data.results && response.data.results.length > 0) {
                                this.props.SET_DASHBOARD_TENANT_VIEWING({ dasboard_tenant_id_selected: response.data.results[0].id, dashboard_tenant_name_selected: response.data.results[0].name_of_tenant });
                                this.setState({
                                    selectedTenant: response.data.results[0].name_of_tenant,
                                    selectedTenantId: response.data.results[0].id,
                                    cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                                }, () => this.autoRefresh());
                            }
                        }

                        else {
                            // we've landed on this page before, so set the local state to the global one

                            this.setState({
                                selectedTenant: this.props.loggedStatus.dashboard_tenant_name_selected,
                                selectedTenantId: this.props.loggedStatus.dashboard_tenant_id_selected,
                                cursor: (this.state.selectedPageButton - 1) * this.state.resultsPerPage
                            }, () => this.autoRefresh());
                        }
                    });
                })
                    .catch(e => {
                        console.log(e);
                    });

            });
        }
    }

    handleDashboardTenantChange(name_of_tenant) {

        // if this is a new selection, update the global state
        if (name_of_tenant !== this.state.selectedTenant) {
            let newTenantId = this.state.tenants.find(o => o.name_of_tenant === name_of_tenant).id;
            this.props.SET_DASHBOARD_TENANT_VIEWING({ dashboard_tenant_id_selected: newTenantId, dashboard_tenant_name_selected: name_of_tenant });

            // this is new tenant selection so ask for data
            this.setState({
                selectedTenant: name_of_tenant,
                selectedTenantId: newTenantId,
                cursor: 0,       // reset the cursor to the backend
                selectedPageButton: 1   // reset the page selected
            }, () => this.autoRefresh());
        }
    }

    // this fxn currently makes sure token is fresh and calls getTaniumChartData - not currently on auto timer, but may change
    autoRefresh() {
        console.log("auto refresh dashboard");
        this.props.onAccessTokenRequest(this.props.loggedStatus.username).then((token) => {
        });

        // uncomment this to turn auto refresh back on
        /*this.timer = setTimeout(() => {

            this.autoRefresh()

        }, 60000)*/
    }



    // AccountDataService.create(data)
    // .then(response => {
    //     //console.log(response.data);
    //     // this.setState({
    //     //     redirect: true
    //     // });
    // })
    // .catch(e => {
    //     console.log(e);
    // });
    //}


    render() {
        // if(!this.props.loggedStatus.loggedIn) {
        //     return (
        //         <Redirect to='/'/>
        //     );
        // }

        // const { redirect } = this.state;

        // if(redirect) {
        //     return <Redirect to='/accounts'/>   // To redirect back after submission
        // }

        return (
            <>
                <Navbar pageTitle={"User Home"} />
                <div className="container mt-3" style={{ paddingTop: "15px" }}></div>
                {/* <div style={{position: "relative", backgroundColor: "pink"}}> */}
                {/* <div className="smallCumulusLogoContainer"> */}
                {/* <img src="Cumulus_Logo.png" alt="server room" style={{width: "10%", float: "right"}}/> */}
                {/* </div> */}
                {/* </div> */}
                {/* <div style={{paddingTop: "20px", paddingLeft: "10px"}}>
                        <img src="Cumulus_Logo.png" alt="server room" style={{width: "33%"}}/>
                </div> */}
                {/* <span className="smallCumulusLogoContainer"><img src="Cumulus_Logo.png" alt="server room" style={{width: "10%", float: "right"}}/></span> */}
                <Container fluid>
                    {/*<Col md={{ span: 8, offset: 2 }}>
                        <div style={{ textAlign: "center", marginLeft: "auto", marginRight: "auto", alignContent: "center" }}>
                            <Card bg="dark" style={{ border: "none", width: "50%", marginRight: "auto", marginLeft: "auto" }}>
                                <Card.Header style={{ color: "white", textAlign: "center" }} className="gothamNarrowFont cardHeader">Admin Functions</Card.Header>
                                <ListGroup variant="flush" style={{ textAlign: "center" }}>
                                    <Link to="/hardwareTickets" className="popular-activities-link-text"><ListGroup.Item className="popular-activities-table-row gothamNarrowLightFont">Tech Support Tickets</ListGroup.Item></Link>
                                </ListGroup>
                            </Card>
                        </div>
                                    </Col>*/}
                    {/*<Grid gutter="sm">
                        <Grid.Col span={6}>
                            <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} w="100%" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Text>Unresolved Tickets by Priority</Text>
                                <Space m="20px" />
                                <Doughnut data={this.state.data} />
                            </Paper>
                            <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} w="100%" mb="10px" display="inline-block" style={{ verticalAlign: "top" }}>
                                <Text>Unresolved Tickets by Date</Text>
                                <Space m="20px" />
                                <Line options={options} data={this.state.line} />
                            </Paper>
                        </Grid.Col>
                            </Grid>*/}
                    {/*<DndContext
                        collisionDetection={closestCenter}
                        onDragStart={handleDragStart}
                        onDragEnd={handleDragEnd}
                        onDragCancel={handleDragCancel}
                    >
                        <div
                            style={{
                                display: 'grid',
                                gridTemplateColumns: `repeat(3, 1fr)`,
                                gridGap: 10,
                                maxWidth: '800px',
                                margin: 'auto',
                            }}
                        >
                            <SortableContext items={this.state.items} strategy={rectSortingStrategy}>
                                <SortableItem key={"1"} index={0} id={"1"}>
                                    1
                                    <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" style={{ verticalAlign: "top" }}>
                                        <Group>
                                            <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                {({ x }) => <RingProgress
                                                    size={80}
                                                    roundCaps
                                                    thickness={8}
                                                    sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                    label={
                                                        <Center>
                                                            {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                        </Center>
                                                    }
                                                />
                                                }
                                            </Motion>

                                            <div>
                                                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                    {"unresolved tickets"}
                                                </Text>
                                                <Text fw={700} size="xl">
                                                    {this.state.unresolvedTickets}
                                                </Text>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SortableItem>
                                <SortableItem key={"2"} index={1} id={"2"}>
                                    2
                                    <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" style={{ verticalAlign: "top" }}>
                                        <Group>
                                            <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                {({ x }) => <RingProgress
                                                    size={80}
                                                    roundCaps
                                                    thickness={8}
                                                    sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                    label={
                                                        <Center>
                                                            {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                        </Center>
                                                    }
                                                />
                                                }
                                            </Motion>

                                            <div>
                                                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                    {"unresolved tickets"}
                                                </Text>
                                                <Text fw={700} size="xl">
                                                    {this.state.unresolvedTickets}
                                                </Text>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SortableItem>
                                <SortableItem key={"3"} index={2} id={"3"}>
                                    3
                                    <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" style={{ verticalAlign: "top" }}>
                                        <Group>
                                            <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                {({ x }) => <RingProgress
                                                    size={80}
                                                    roundCaps
                                                    thickness={8}
                                                    sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                    label={
                                                        <Center>
                                                            {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                        </Center>
                                                    }
                                                />
                                                }
                                            </Motion>

                                            <div>
                                                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                    {"unresolved tickets"}
                                                </Text>
                                                <Text fw={700} size="xl">
                                                    {this.state.unresolvedTickets}
                                                </Text>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SortableItem>
                                <SortableItem key={"4"} index={3} id={"4"}>
                                    4
                                    <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" style={{ verticalAlign: "top" }}>
                                        <Group>
                                            <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                {({ x }) => <RingProgress
                                                    size={80}
                                                    roundCaps
                                                    thickness={8}
                                                    sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                    label={
                                                        <Center>
                                                            {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                        </Center>
                                                    }
                                                />
                                                }
                                            </Motion>

                                            <div>
                                                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                    {"unresolved tickets"}
                                                </Text>
                                                <Text fw={700} size="xl">
                                                    {this.state.unresolvedTickets}
                                                </Text>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SortableItem>
                                <SortableItem key={"5"} index={4} id={"5"}>
                                    5
                                    <Paper withBorder radius="md" p="15px" key={"lowDiskSpace"} h="115px" w="400px" mb="10px" style={{ verticalAlign: "top" }}>
                                        <Group>
                                            <Motion defaultStyle={{ x: 0 }} style={{ x: spring((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100) }}>
                                                {({ x }) => <RingProgress
                                                    size={80}
                                                    roundCaps
                                                    thickness={8}
                                                    sections={[{ value: x, color: ((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) > 0.8 ? "red" : "blue") }]}
                                                    label={
                                                        <Center>
                                                            {Math.round((this.state.unresolvedTickets / (this.state.totalTickets === 0 ? 1 : this.state.totalTickets)) * 100)}%
                                                        </Center>
                                                    }
                                                />
                                                }
                                            </Motion>

                                            <div>
                                                <Text c="dimmed" size="xs" tt="uppercase" fw={700}>
                                                    {"unresolved tickets"}
                                                </Text>
                                                <Text fw={700} size="xl">
                                                    {this.state.unresolvedTickets}
                                                </Text>
                                            </div>
                                        </Group>
                                    </Paper>
                                </SortableItem>
                            </SortableContext>
                            <DragOverlay adjustScale style={{ transformOrigin: '0 0 ' }}>
                                {this.state.activeId ? <Item id={this.state.activeId} isDragging /> : null}
                            </DragOverlay>
                        </div>
                                            </DndContext>*/}
                </Container>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        loggedStatus: state.logged,
        activeNavLinkStatus: state.activeNav,
        userPermissions: state.userPermissions
    };
}

export default connect(mapStateToProps, { SET_DASHBOARD_TENANT_VIEWING })(AdminDashboard);