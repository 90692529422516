import http from "../http-common";

class DashboardService {

    getUnresolvedTicketCount(token, user_id_asking) {
        return http.get(`/dashboard?userIdAsking=${user_id_asking}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getUnresolvedTicketsByPriority(token, user_id_asking, tenant) {
        return http.get(`/dashboard?userIdAsking=${user_id_asking}&tenant=${tenant}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    getWidgets(token, user_id_asking, dashboardName) {
        return http.get(`/dashboard/getwidgets?associatedUserId=${user_id_asking}&dashboardName=${dashboardName}`, {headers: {Authorization: 'Bearer ' + token}});
    }

    setWidgets(token, user_id_asking, widgetsData, dashboardName) {
        return http.post(`/dashboard/setwidgets?associatedUserId=${user_id_asking}&dashboardName=${dashboardName}`, widgetsData, {headers: {Authorization: 'Bearer ' + token}});
    }

}


export default new DashboardService();